@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Nunito Sans", -apple-system, "Roboto", "Oxygen",
    "Helvetica Neue", "Arial", "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#hero path {
  stroke-dasharray: 920;
  stroke-dashoffset: 920;
  animation: sign 4s ease;
  animation-fill-mode: forwards;
}

@keyframes sign {
  to {
    stroke-dashoffset: 0;
  }
}
